<template>
  <v-dialog
    v-model="show"
    :max-width="options.width"
    :style="{ zIndex: options.zIndex }"
    @keydown.esc="cancel"
  >
    <v-form ref="form">
      <v-card>
        <v-toolbar :color="options.color" dark dense flat>
          <v-toolbar-title class="white--text">{{ title }}</v-toolbar-title>
        </v-toolbar>
        <v-card-text v-show="!!message" class="pa-4">{{ message }}</v-card-text>
        <v-textarea class="mx-2" placeholder="Escribe aquí el motivo..." :rules="[ options.addExplanation === true ? req : true]" auto-grow rows="1" no-resize v-model="explanation" filled v-if="options.addExplanation" />
        <v-card-actions class="pt-0 mt-2">
          <v-spacer></v-spacer>
          <v-btn @click.native="cancel" :color="options.cancelColor" text>Cancelar</v-btn>
          <v-btn @click.native="agree" :color="options.confirmColor">Confirmar</v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import { req } from '@/utils/validations.js'

export default {
  data: () => ({
    dialog: false,
    resolve: null,
    reject: null,
    message: null,
    title: null,
    explanation: null,
    options: {
      color: 'primary',
      width: 400,
      zIndex: 200,
      cancelColor: 'grey',
      confirmColor: 'primary',
      addExplanation: false,
    },
    req
  }),
  computed: {
    show: {
      get() {
        return this.dialog
      },
      set(value) {
        this.dialog = value
        if (value === false) {
          this.cancel()
        }
      }
    }
  },
  methods: {
    open(title, message, options) {
      this.dialog = true
      this.title = title
      this.message = message
      this.options = Object.assign(this.options, options)
      return new Promise((resolve, reject) => {
        this.resolve = resolve
        this.reject = reject
      })
    },
    agree() {
      if (!this.$refs.form.validate()) return;
      this.resolve( this.explanation || true)
      this.dialog = false
    },
    cancel() {
      this.resolve(false)
      this.dialog = false
    }
  }
}
</script>